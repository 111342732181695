'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useResendNewEmailActivationMutation } from '@edeeone/edee-registration/graphql/user-profile/requests/resendNewEmailActivation.codegen';
import { Button } from '@edeeone/juan-core/components/button/Button';
export const ResendNewEmailActivationButton = ({ inactiveEmail, login, children, }) => {
    const [resendNewEmailActivation, { loading }] = useResendNewEmailActivationMutation();
    function handleResendEmailActivation() {
        resendNewEmailActivation({
            variables: {
                input: {
                    email: inactiveEmail,
                    login,
                },
            },
        });
    }
    return (_jsx(Button, { design: "quaternary", onClick: handleResendEmailActivation, loading: loading, disabled: loading, children: children }));
};
