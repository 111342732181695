'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { loginFormClassNames } from '@edeeone/edee-registration/components/loginForm/LoginForm.styles';
import { useVariantParam } from '@edeeone/edee-registration/hooks/useVariantParam';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import Link from '@edeeone/juan-core/Link';
import { linkOnClickShallow } from '@edeeone/juan-core/utils/linkOnClickShallow';
import { getHref } from '@edeeone/string/getHref';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
export const RegistrationSection = ({ styles, design, inModal, isServerModal, action, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { registrationUrl } = useLocation();
    const { pathname, variantParam } = useVariantParam();
    const { triggerModalServerAction } = useServerActionModal();
    const handleOpenRegister = (e) => {
        e.stopPropagation();
        if (!action) {
            return;
        }
        triggerModalServerAction(action, {
            actionType: 'REGISTER',
        });
    };
    return (_jsx("p", { className: finalStyles.note, children: t('noAccount', {
            a: (chunks) => {
                if (isServerModal) {
                    return (_jsx(Button, { design: "quaternary", className: finalStyles.note_a, onClick: handleOpenRegister, children: chunks }));
                }
                if (inModal) {
                    return (_jsx(Link, { href: getHref(pathname, { ...variantParam, user: 2 }), onClick: linkOnClickShallow, className: finalStyles.note_a, children: chunks }));
                }
                return (_jsx(Link, { href: registrationUrl, legacyBehavior: true, children: _jsx("a", { className: finalStyles.note_a, children: chunks }) }));
            },
        }) }));
};
