'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { loginFormClassNames } from '@edeeone/edee-registration/components/loginForm/LoginForm.styles';
import { useVariantParam } from '@edeeone/edee-registration/hooks/useVariantParam';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import Link from '@edeeone/juan-core/Link';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { linkOnClickShallow } from '@edeeone/juan-core/utils/linkOnClickShallow';
import { getHref } from '@edeeone/string/getHref';
import { useFormContext } from 'react-hook-form';
export const ResetPasswordSection = ({ styles, design, inModal, isServerModal, action, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { lostPasswordUrl } = useLocation();
    const { watch } = useFormContext();
    const { pathname, variantParam } = useVariantParam();
    const { triggerModalServerAction } = useServerActionModal();
    const email = watch('login');
    const forgottenPasswordQueryProp = email ? { query: { email } } : {};
    const label = t('forgottenPassword');
    const handleOpenResetPassword = (e) => {
        e.stopPropagation();
        if (!action) {
            return;
        }
        triggerModalServerAction(action, {
            actionType: 'RESET_PASSWORD',
        });
    };
    const showServerModalButton = isServerModal;
    const showModalLink = !isServerModal && inModal;
    const showLink = !isServerModal && !inModal;
    return (_jsxs("p", { className: finalStyles.checkboxLink, children: [showServerModalButton && (_jsx(Button, { design: "quaternary", className: finalStyles.checkboxLink_a, onClick: handleOpenResetPassword, children: label })), showModalLink && (_jsx(Link, { href: getHref(pathname, {
                    ...variantParam,
                    ...forgottenPasswordQueryProp.query,
                    user: 3,
                }), onClick: linkOnClickShallow, className: finalStyles.checkboxLink_a, children: label })), showLink && (_jsx(Link, { href: getHref(lostPasswordUrl, {
                    ...forgottenPasswordQueryProp.query,
                }), legacyBehavior: true, ...forgottenPasswordQueryProp, children: _jsx("a", { className: finalStyles.checkboxLink_a, children: label }) }))] }));
};
