'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { loginFormClassNames } from '@edeeone/edee-registration/components/loginForm/LoginForm.styles';
import { ResendNewEmailActivationButton } from '@edeeone/edee-registration/components/profile/ResendNewEmailActivationButton';
import { Alert } from '@edeeone/juan-core/components/alert/Alert';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useFormContext } from 'react-hook-form';
export const ErrorAlert = ({ styles, design, alertSize, error, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { watch } = useFormContext();
    if (!error || error.type === 'captchaError') {
        return null;
    }
    const email = watch('login');
    return (_jsx("div", { className: finalStyles.alertContainer, children: _jsx(Alert, { type: 'error', size: alertSize, text: t(error.title), note: t(error.description, {
                a: (resendActivationMailChunk) => {
                    return (_jsx(ResendNewEmailActivationButton, { inactiveEmail: email, login: email, children: resendActivationMailChunk }));
                },
            }) }) }));
};
